import React, {
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import CreationsCommon from './CreationsCommon'

const LatestCreations = ({ data }) => (
  <CreationsCommon
    data={data}
    sectionTitle="Latest Creations"
    seeMoreHref="/creations"
  />
)

LatestCreations.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
    query LatestCreations {
      allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "creations-post"}, hidePost: {eq: false}}}, limit:4) {
        edges {
          node {
            excerpt(pruneLength: 100)
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
              date(formatString: "MMMM DD, YYYY")
              featuredPost
              featuredImage {
                id
                childImageSharp {
                  fluid(maxWidth: 500, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
                colors {
                  ...GatsbyImageColors
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data, count) => <LatestCreations data={data} count={count} />}
  />
)
