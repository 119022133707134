import React, {
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Helmet } from 'react-helmet'
import CreationsRollDefault from '../components/CreationsRoll'
import CustomPageContext from '../Context/CustomPageContext'
import Layout from '../components/Layout'
import FeaturedCreations from '../components/FeaturedCreations'
import LatestCreations from '../components/LatestCreations'
import MainPitch from '../components/MainPitch'
import AboutSection from '../components/AboutSection'
import { HTMLContent } from '../components/Content'
import appConfig from '../config/config'

const useTemplateStyles = makeStyles((theme) => ({
  root: {},
  topSection: ({ image }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&::before': {
      content: '" "',
      position: 'fixed', // instead of background-attachment
      width: '100%',
      height: '70vh',
      backgroundColor: theme.palette.background.paper,
      background: `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), 
      url(${image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      willChange: 'transform', // creates a new paint layer
      zIndex: -1,
    }
  }),

  heading: {
    minHeight: '20rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  headingText: {
    transform: 'skew(-15deg)',
    fontSize: '5vmax',
    backgroundColor: '#ffffffd6',
    color: '#000000',
    padding: '0.1rem 1rem',
    margin: '0.3rem 0',
  },
  headingCreations: {
    marginRight: '25%',
    animation: `creationsHeadingAnimation 5s infinite ${theme.transitions.easing.easeIn}`
  },
  headingBy: {
    animation: `pulsate 5s infinite ${theme.transitions.easing.easeInOut}`
  },
  headingNilay: {
    marginLeft: '8%',
    animation: `nilayHeadingAnimation 5s infinite ${theme.transitions.easing.easeIn}`
  },
  contentSection: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    margin: 0,
    padding: '1rem 0',
  },
}))

export const IndexPageTemplate = ({
  location,
  image,
  mainpitch,
  about,
  helmet,
  children,
}) => {
  const classes = useTemplateStyles({ image })
  const [mountHeading, setMountHeading] = useState(false)

  useEffect(() => {
    setTimeout(() => setMountHeading(true), [1000])
    setTimeout(() => setMountHeading(false), [2000])
  }, [])

  return (
    <div className={classes.root}>
      {helmet || ''}
      <div className={classes.topSection}>
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={8}
            lg={7}
            xl={4}
          >
            <div className={classes.heading}>
              <div className={classes.headingCreations}>
                <div className={classes.headingText}>
                  Creations
                </div>
              </div>
              <div className={classes.headingBy}>
                <div className={classes.headingText}>
                  By
                </div>
              </div>
              <div className={classes.headingNilay}>
                <div className={classes.headingText}>
                  Nilay
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentSection}>
        {mainpitch && mainpitch.title && mainpitch.description && (
          <MainPitch
            title={mainpitch.title}
            subtitle={mainpitch.description}
          />
        )}
        <FeaturedCreations />
        <LatestCreations />
        {about && about.frontmatter && about.html && (
          <AboutSection
            title={about.frontmatter.contentTitle}
            profileImage={about.frontmatter.profileImage}
          >
            <HTMLContent content={about.html} />
          </AboutSection>
        )}
      </div>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  location: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mainpitch: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  about: PropTypes.shape({
    html: PropTypes.node,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      contentTitle: PropTypes.string,
    })
  }),
  helmet: PropTypes.node,
  children: PropTypes.node,
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.index
  const { site } = data

  const initUiTheme = typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(initUiTheme || appConfig.defaultInitTheme)

  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        // type: prefersDarkMode ? 'dark' : 'light',
        type: uiTheme,
        primary: { main: '#212121' },
        secondary: { main: '#DD2C00' }
      },
    }),
    [uiTheme],
  )
  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <IndexPageTemplate
            location={location}
            image={frontmatter.image}
            mainpitch={frontmatter.mainpitch}
            about={data.about}
            // helmet={(
            //   <Helmet titleTemplate="%s">
            //     <title>{`${site.siteMetadata.title}`}</title>
            //     <meta
            //       name="description"
            //       content={`${site.siteMetadata.description}`}
            //     />
            //   </Helmet>
            // )}
          />
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      })
    }),
    index: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        image: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.object,
          }),
        }),
        mainpitch: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        })
      }),
    }),
    about: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.node,
    }),
  }),
  location: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    site {
      siteMetadata {
        title
        description
      }
    }
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
          description
        }
      }
    }
    about: markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        description
        profileImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contentTitle
      }
    }
  }
`
