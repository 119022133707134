import React from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomPageContext from '../Context/CustomPageContext'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    padding: '1rem 0rem',
    paddingTop: 0,

    overflow: 'hidden', /* Ensures the content is not revealed until the animation */
    borderRight: `.20em solid ${theme.palette.secondary.light}`, /* The typwriter cursor */
    whiteSpace: 'nowrap', /* Keeps the content on a single line */
    margin: '0 auto', /* Gives that scrolling effect as the typing happens */
    letterSpacing: '.15em', /* Adjust as needed */
    animation: `
    typing 2.5s steps(20, end),
    blink-caret .75s step-end infinite
    `,
  },
  subtitle: {
    textAlign: 'center',
    animation: 'fadeUp 5s',
  },

}))

const MainPitch = ({ title, subtitle }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      </div>
      <Typography variant="h5" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </div>
  )
}

MainPitch.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

MainPitch.defaultProps = {
  title: 'Mainpitch Title',
  subtitle: 'MainPitch Subtitle',
}

export default MainPitch
