import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomLink from './CustomLink'
import CreationsRollDefault, { CreationsRoll } from './CreationsRoll'

const useStyles = makeStyles((theme) => ({
  featuredSection: ({ bottomBorder }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    // background: theme.palette.background.paper,

    '&::after': {
      content: '""',
      background: theme.palette.primary.light,
      height: '20px',
      display: bottomBorder ? 'block' : 'none',
    }
  }),
  featuredLabelSection: {
    padding: '1rem 2rem',
    paddingBottom: 0,
  },

  featuredLabel: {
    background: theme.palette.getContrastText(theme.palette.background.default),
    transform: 'skew(-15deg)',
    color: theme.palette.getContrastText(
      theme.palette.getContrastText(theme.palette.background.default),
    ),
    padding: '0.5rem 1rem',
    width: 'fit-content',
    boxShadow: '5px 5px 0px 1px grey',
  },
  seeMoreSection: {
    padding: '1rem 2rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  seeMoreLabel: {
    background: theme.palette.secondary.main,
    transform: 'skew(-15deg)',
    color: theme.palette.getContrastText(
      theme.palette.secondary.main
    ),
    padding: '0.2rem 1rem',
    width: 'fit-content',
    boxShadow: '5px 5px 0px 1px grey',

    '&:active': {
      transform: 'translateY(10%) skew(-15deg)',
      boxShadow: '5px 5px 0px 0px grey',
    }
  },
}))

const CreationsCommon = ({
  data, sectionTitle, bottomBorder, seeMoreHref
}) => {
  const classes = useStyles({ bottomBorder })

  return (
    <div className={classes.featuredSection}>
      <div className={classes.featuredLabelSection}>
        <Typography variant="h5" className={classes.featuredLabel}>
          {sectionTitle}
        </Typography>
      </div>
      {data && (
        <CreationsRoll
          data={data}
          style={{
            margin: 0,
          }}
        />
      )}
      {seeMoreHref && (
        <div className={classes.seeMoreSection}>
          <CustomLink to={seeMoreHref}>
            <Typography variant="h5" className={classes.seeMoreLabel}>
              See More
            </Typography>
          </CustomLink>
        </div>
      )}
    </div>
  )
}

CreationsCommon.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  sectionTitle: PropTypes.string,
  bottomBorder: PropTypes.bool,
  seeMoreHref: PropTypes.string,
}

CreationsCommon.defaultProps = {
  sectionTitle: '',
  bottomBorder: true,
}

export default CreationsCommon
